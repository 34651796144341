import * as React from 'react'
function SvgCrop() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.185 5.058a.65.65 0 10-1.3 0v2.327H5.558a.65.65 0 100 1.3h2.327v4.792a2.139 2.139 0 002.138 2.138h4.792v2.327a.65.65 0 001.3 0v-2.327h2.327a.65.65 0 100-1.3h-2.327V9.523a2.138 2.138 0 00-2.138-2.138H9.185V5.058zm0 3.627v4.792a.838.838 0 00.838.838h4.792V9.523a.838.838 0 00-.838-.838H9.185z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgCrop
