import * as React from 'react'
function SvgSendUsFeedback() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.25 15.75a1.834 1.834 0 01-1.833 1.833h-11L3.75 21.25V6.583A1.833 1.833 0 015.583 4.75h12.834a1.833 1.833 0 011.833 1.833v9.167z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.167 8.417l-2.75 2.75 2.75 2.75"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.583 13.917V13a1.833 1.833 0 00-1.833-1.833H7.417"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export default SvgSendUsFeedback
