import * as React from 'react'
function SvgFilesSurveyMessage() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.667 6.5A1.167 1.167 0 004.5 7.667v12.126l2.48-2.48a.5.5 0 01.353-.146h10A1.167 1.167 0 0018.5 16V7.667A1.167 1.167 0 0017.333 6.5H5.667zm-1.532-.365A2.167 2.167 0 015.667 5.5h11.666A2.167 2.167 0 0119.5 7.667V16a2.167 2.167 0 01-2.167 2.167H7.54l-3.186 3.187A.5.5 0 013.5 21V7.667c0-.575.228-1.126.635-1.532z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFilesSurveyMessage
