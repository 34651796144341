import * as React from 'react'
function SvgLeftMenuChatList() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.75 4.5H17A2.5 2.5 0 0119.5 7v10a2.5 2.5 0 01-2.5 2.5h-5.25v-15zm-1.5 0H7A2.5 2.5 0 004.5 7v10A2.5 2.5 0 007 19.5h3.25v-15zM3 7a4 4 0 014-4h10a4 4 0 014 4v10a4 4 0 01-4 4H7a4 4 0 01-4-4V7zm4-.75a.75.75 0 000 1.5h1a.75.75 0 000-1.5H7zm0 3a.75.75 0 000 1.5h1a.75.75 0 000-1.5H7z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgLeftMenuChatList
