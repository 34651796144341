import * as React from 'react'
function SvgFilter() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 8.25a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H6.75A.75.75 0 016 8.25zm2 4a.75.75 0 01.75-.75h6.5a.75.75 0 010 1.5h-6.5a.75.75 0 01-.75-.75zm2.75 3.25a.75.75 0 000 1.5h2.5a.75.75 0 000-1.5h-2.5z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFilter
