import * as React from 'react'
function SvgNewChatSquarePen() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.904 4.668a1.01 1.01 0 011.428 1.428l-7.477 7.478a.908.908 0 01-.388.23l-1.796.525.525-1.795a.909.909 0 01.23-.388l7.478-7.478zm.714-1.796a2.51 2.51 0 00-1.775.735l-7.476 7.478a2.409 2.409 0 00-.61 1.026v.001l-.697 2.383v.001a1.165 1.165 0 001.444 1.444l2.384-.697a2.41 2.41 0 001.027-.608l7.478-7.478a2.51 2.51 0 00-1.775-4.285zM6.278 3.7a2.41 2.41 0 00-2.41 2.41v11.613a2.409 2.409 0 002.41 2.41h11.614a2.409 2.409 0 002.41-2.41v-5.807a.75.75 0 10-1.5 0v5.807a.909.909 0 01-.91.91H6.278a.91.91 0 01-.91-.91V6.108a.91.91 0 01.91-.91h5.807a.75.75 0 000-1.5H6.278z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgNewChatSquarePen
