import * as React from 'react'
function SvgGearLine() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.928 4h-.33a1.5 1.5 0 00-1.5 1.5v.135a1.5 1.5 0 01-.75 1.298l-.322.187a1.5 1.5 0 01-1.5 0l-.113-.06a1.5 1.5 0 00-2.047.548l-.165.285a1.5 1.5 0 00.547 2.047l.113.075a1.5 1.5 0 01.75 1.29v.383a1.5 1.5 0 01-.75 1.304l-.113.068a1.5 1.5 0 00-.547 2.047l.165.286a1.5 1.5 0 002.047.547l.113-.06a1.5 1.5 0 011.5 0l.322.187a1.5 1.5 0 01.75 1.298v.135a1.5 1.5 0 001.5 1.5h.33a1.5 1.5 0 001.5-1.5v-.135a1.5 1.5 0 01.75-1.297l.323-.188a1.5 1.5 0 011.5 0l.112.06a1.5 1.5 0 002.048-.547l.165-.293a1.5 1.5 0 00-.548-2.047l-.112-.06a1.5 1.5 0 01-.75-1.306v-.374a1.499 1.499 0 01.75-1.306l.112-.067a1.5 1.5 0 00.548-2.047l-.165-.285a1.5 1.5 0 00-2.048-.548l-.112.06a1.5 1.5 0 01-1.5 0l-.323-.188a1.5 1.5 0 01-.75-1.297V5.5a1.5 1.5 0 00-1.5-1.5z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.763 13.75a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export default SvgGearLine
