import * as React from 'react'
function SvgUserInvite() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.583 19.625a6.333 6.333 0 0110.523-4.75M10.917 13.292a3.958 3.958 0 100-7.917 3.958 3.958 0 000 7.917zM18.042 15.667v4.75M20.417 18.042h-4.75"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export default SvgUserInvite
